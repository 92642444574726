<template>
  <div class="page">
    <div class="dkzc-box min400" v-loading="loading">
      <div class="title">{{ titleList[$route.query.type - 3] }}</div>
      <div class="vhtml" v-html="content.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: {},
      loading: false,
      titleList: [
        "考核机构",
        "考核方式",
        "执业记录",
        "考核程序",
        "考核结果",
        "监督管理",
      ],
    };
  },
  created() {
    this.getHomeGet_home();
    window.scrollTo({
      left: 0,
      top: 0,
    });
  },

  methods: {
    async getHomeGet_home() {
      this.loading = true;
      let { code, data } = await this.$newsApi.getHomeGet_home({
        type: this.$route.query.type,
      });
      this.loading = false;
      if (code == 200) {
        this.content = data;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  > .dkzc-box {
    // display: flex;
    // flex-direction: row;
    width: 1200px;
    margin: 0 auto;
    margin-top: 30px;
    > .title {
      font-size: 18px;
      color: #333333;
      font-weight: 600;
      margin-bottom: 42px;
    }
  }
}
</style>
